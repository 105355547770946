.modal-container {
    padding: 2rem;
}

.modal-img {
    width: 70%;
    border: 3px solid #77ff00;
    border-radius: 3px;
    margin-bottom: 1rem;
}

.img-content {
    width: 50%;
    display: flex;
    justify-content: end;
    align-items: end;
}

.texts-modal {
    width: 50%;
}

.texts-container {
    height: 50%;
    flex-direction: row-reverse;
    display: flex;
    justify-content: space-between;
}

.text-name {
    color: aliceblue;
}

.text-description {
    height: 20rem;
    /* font-size: small; */
    /* overflow: hidden; */
    font-size: x-small;
    color: rgb(255, 255, 255);
    /* text-align: justify; */
    /* text-overflow: ellipsis; */
    /* text-justify: inter-word; */
}

.social-container {
    height: 50%;
}

.sossial-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .modal-container {
        padding: 0rem;
    }

    .modal-img {
        width: 50%;
    }

    .img-content {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .texts-modal {
        width: 100%;
    }

    .texts-container {
        display: inline;
    }

    .text-name {
        text-align: center;
    }

    .text-description {
        font-size: x-small;
    }

    .social-content {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}