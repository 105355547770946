.img-logo-p {
    width: auto;
    height: auto;
}

@media (max-height: 750px) {
    .img-logo-p {
        margin-top: 10%;
        width: 60%;
    }
}