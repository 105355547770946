li {
    color: #bbbb; /* Change to your desired color */
  }
b {
    color: #bbbb; /* Change to your desired color */
  }

  .div-card {
    box-shadow: rgba(69, 76, 83, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
    border: 1px solid #303136;
    padding: 1rem;
    margin: 1rem;
    width: 400px;
  }
  
  .div-card-container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .compa {
    opacity: 0;
    transform: translateY(-50px);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateY(-50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .compa.visible {
    opacity: 1;
    transform: translateY(0);
    animation: slideIn 0.5s ease forwards;
  }