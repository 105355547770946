.ticket-type {
  color: #fff;
  font-size: 3em;
  font-weight: bold;
  text-shadow: #ccc 1px 0 10px;
  text-shadow: 1px 1px 2px rgb(0, 0, 0);
}

.typo {
  color: #fff;
  font-size: 1.5em;

}

.full-price {
  text-decoration: line-through;
  color: #ffffff;
  text-shadow: 1px 1px 2px rgb(0, 0, 0);
  font-size: 1.3em;
  font-weight: bold;
}

.p-v {
  text-shadow: 1px 1px 2px rgb(0, 0, 0);
}

.text-spetial-sale {
  text-align: center;
  color: #22f3e5;
  font-weight: bold;
  text-shadow: #ccc 1px 0 10px;
  text-shadow: 1px 1px 2px rgb(0, 0, 0);
}

.promo-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.promo-text h2 {
  font-size: 24px;
  font-weight: bold;
  color: #eb445a;
}

.promo-text p {
  font-size: 16px;
  color: black;
}

.promo-text--highlight {
  background-color: yellow;
  font-style: italic;
}